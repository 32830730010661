import { Skeleton } from '@mui/material';
import { styled } from '@mui/system';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

export default function UserProfileSkeleton({ themeParams }) {
    const StyledCard = styled(Card)({
        backgroundColor: themeParams.secondary_bg_color,
        borderRadius: '20px',
        margin: '20px'
    });

    return (
        <StyledCard className="max-w-md mx-auto mt-10">
            <CardContent className="flex flex-col items-center p-6">
                <div className="flex flex-row items-center w-full justify-center mb-4">
                    <Skeleton variant="circular" width={40} height={40} className="mr-4" />
                    <Skeleton variant="text" width={150} height={20} />
                </div>
                <Skeleton variant="text" width={100} height={20} className="text-center mb-4" />
                <Skeleton variant="rectangular" width="100%" height={40} className="mt-4 w-full" />
            </CardContent>
        </StyledCard>
    );
}
