import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/system';

const StyledHintTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
    textAlign: 'center',
    marginBottom: theme.spacing(2.5), // Equivalent to 20px
    cursor: 'pointer' // Добавляем курсор указателя для интерактивности
}));

const ExpandableText = ({ text }) => {
    const [expanded, setExpanded] = useState(false);

    const toggleExpand = () => {
        setExpanded(!expanded);
    };

    return (
        <StyledHintTypography onClick={toggleExpand} className="text-center mb-20">
            {expanded ? text : `${text.substring(0, 100)}...`}
        </StyledHintTypography>
    );
};

export default ExpandableText;