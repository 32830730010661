import React from 'react';
import { Typography } from "@mui/material";

function Video({ step }) {
    const video_url = step.payload?.video_url;

    const playerWrapperStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: 'none'
    };

    return (
        <>
        </>
    );
}

export default Video;
