// src/Notifications.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, CardContent, Typography, List, ListItem, ListItemText, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import SkeletonPayment from './SkeletonPayment';
import { translate } from './translations'; // Import the translate function
import { useDispatch } from 'react-redux';
import { setTab, setSelectedAssignment } from './store'; // Import the Redux actions

const Notifications = ({ token, themeParams, language, course }) => {
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [isFetching, setIsFetching] = useState(false);
    const dispatch = useDispatch();

    const StyledCard = styled(Card)({
        backgroundColor: themeParams.section_bg_color,
        borderRadius: '20px',
        margin: '20px'
    });

    const PageTitle = styled(Typography)({
        color: themeParams.text_color,
        textAlign: 'center',
        margin: '20px 0',
    });

    const hexToRgb = (hex) => {
        let bigint = parseInt(hex.substring(1), 16);
        let r = (bigint >> 16) & 255;
        let g = (bigint >> 8) & 255;
        let b = bigint & 255;
        return [r, g, b];
    };

    const rgbToCss = (rgb) => `rgb(${rgb.join(', ')})`;

    const lightenColor = (rgb, amount) => {
        return rgb.map(value => Math.min(255, value + amount));
    };

    const primaryColorRgbArray = hexToRgb(themeParams.bg_color); // --tg-theme-bg-color
    const primaryColorRgb = rgbToCss(primaryColorRgbArray);

    const secondaryColorRgbArray = lightenColor(primaryColorRgbArray, 50); // Increase brightness
    const secondaryColorRgb = rgbToCss(secondaryColorRgbArray);

    const StyledCardHeader = styled(Card)(({ theme }) => ({
        background: `linear-gradient(${secondaryColorRgb} 0.06%, ${primaryColorRgb} 128.06%)`,
        width: '100%',
        textAlign: 'center',
        padding: '20px',
    }));

    const SectionNameTypography = styled(Typography)({
        color: themeParams.section_header_text_color,
        fontSize: '0.80rem',
        marginLeft: '40px',
        marginTop: '20px',
        textAlign: 'left',
        textTransform: 'uppercase',
        letterSpacing: '0.1em',
    });

    useEffect(() => {
        const fetchNotifications = async () => {
            setIsFetching(true);
            try {
                const response = await axios.get(`/v1/notifications?size=10&page=${page}&course_id=${course.id}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const newNotifications = response.data.data || [];
                setNotifications(prevNotifications => [...prevNotifications, ...newNotifications]);
                setHasMore(page < response.data.pagination.total_pages);
                setLoading(false);
                setIsFetching(false);
            } catch (error) {
                console.error("Ошибка при загрузке уведомлений:", error);
                setLoading(false);
                setIsFetching(false);
            }
        };

        fetchNotifications();
    }, [page, token, course.id]);

    useEffect(() => {
        const handleScroll = () => {
            if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight || isFetching || !hasMore) return;
            setPage(prevPage => prevPage + 1);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isFetching, hasMore]);

    const handleNotificationClick = async (notification) => {
        if (notification.type === 'assignment') {
            try {
                const response = await axios.get(`/v2/assignments/${notification.entity_id}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const assignment = response.data.data;
                dispatch(setSelectedAssignment(assignment));
                dispatch(setTab(1)); // Change tab to Assignments
            } catch (error) {
                console.error("Ошибка при загрузке задания:", error);
            }
        }
    };

    return (
        <div>
            <SectionNameTypography>
                {translate(language, 'notifications')}
            </SectionNameTypography>
            <List>
                {notifications.length > 0 ? (
                    notifications.map(notification => (
                        <StyledCard key={notification.id} onClick={() => handleNotificationClick(notification)}>
                            <ListItem>
                                <ListItemText
                                    primary={<span dangerouslySetInnerHTML={{ __html: notification.message.replace(/\n/g, '<br/>') }} />}
                                    secondary={translate(language, 'date') + ": " + new Date(notification.created_at).toLocaleString()}
                                />
                            </ListItem>
                        </StyledCard>
                    ))
                ) : (
                    !loading && (
                        <Typography variant="body2" color="textSecondary" align="center" marginY={2}>
                            {translate(language, 'no_notifications')}
                        </Typography>
                    )
                )}
            </List>
            {loading && (
                <>
                    <SkeletonPayment themeParams={themeParams} />
                    <SkeletonPayment themeParams={themeParams} />
                </>
            )}
            {isFetching && (
                <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
                    <CircularProgress />
                </div>
            )}
            {!hasMore && !loading && notifications.length > 0 && (
                <Typography variant="body2" color="textSecondary" align="center" marginY={2}>
                    {translate(language, 'no_more_notifications')}
                </Typography>
            )}
        </div>
    );
};

export default Notifications;