import React from 'react';
import { Card, CardContent, ListItem, ListItemAvatar, ListItemText, Skeleton } from '@mui/material';
import { styled } from '@mui/system';


const SkeletonPayment = ({themeParams}) => {
    const StyledCard = styled(Card)({
        backgroundColor: themeParams.section_bg_color,
        borderRadius: '20px',
        margin: '20px'
    });

    return (
        <StyledCard>
            <CardContent>
                <ListItem>
                    <ListItemAvatar>
                        <Skeleton variant="circular" width={40} height={40} />
                    </ListItemAvatar>
                    <ListItemText
                        primary={<Skeleton variant="text" width="80%" />}
                        secondary={<Skeleton variant="text" width="60%" />}
                    />
                </ListItem>
                <Skeleton variant="text" width="40%" />
                <Skeleton variant="text" width="20%" />
                <Skeleton variant="text" width="30%" />
            </CardContent>
        </StyledCard>
    );
};

export default SkeletonPayment;