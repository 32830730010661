const translations = {
    en: {
        completed: "Done!",
        continue: "Keep Going",
        profile: "My Profile",
        assignments: "Tasks",
        payments: "Payments",
        lessons: "Lessons",
        dashboard: "Home",
        progress: "Progress",
        edit_profile: "Update Profile",
        attempts: "Tries",
        comments: "Feedback",
        created: "Created",
        attempt: "Try",
        start: "Start",
        no_notifications: "No notifications to show.",
        no_assignments: "No tasks to show.",
        status: "Status",
        notifications: "Notifications",
        date: "Date",
        no_payments: "No completed payments ⭐",
        current_step_locked: "Complete this step to unlock the next one.",
        step_locked: "Complete the previous steps so that the content is available.",
        reset_progress: "Reset Progress",
        reset_confirm_message: "Are you sure you want to reset your progress? This action cannot be undone."
    },
    ru: {
        completed: "Готово!",
        reset_progress: "Сбросить Прогресс",
        reset_confirm_message: "Вы уверены, что хотите сбросить свой прогресс? Это действие нельзя отменить.",
        continue: "Продолжить",
        start: "Начать",
        profile: "Мой Профиль",
        notifications: "Уведомления",
        no_notifications: "Нет уведомлений для показа.",
        assignments: "Задания",
        lessons: "Уроки",
        payments: "Платежи",
        dashboard: "Главная",
        progress: "Прогресс",
        edit_profile: "Обновить Профиль",
        attempts: "Попытки",
        comments: "Отзывы",
        created: "Создано",
        attempt: "Попробовать",
        no_assignments: "Нет заданий для показа.",
        status: "Статус",
        date: "Дата",
        no_payments: "Нет совершенных платежей ⭐",
        current_step_locked: "Завершите этот шаг, чтобы разблокировать следующий.",
        step_locked: "Завершите предыдущее шаги, чтобы контент был доступен."
    }
};

export function translate(languageCode, key) {
    return translations[languageCode]?.[key] || translations.en[key];
}