import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, CardContent, Typography, Avatar, List, ListItem, ListItemAvatar, ListItemText, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import SkeletonPayment from './SkeletonPayment';
import { translate } from './translations'; // Import the translate function

const AmountTypography = styled(Typography)(({ theme, color }) => ({
    color: color,
}));

const Payments = ({ token, themeParams, courseID, language }) => {
    const [payments, setPayments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [isFetching, setIsFetching] = useState(false);

    const StyledCard = styled(Card)({
        backgroundColor: themeParams.section_bg_color,
        borderRadius: '20px',
        margin: '20px'
    });

    const CourseTag = styled("span")({
        backgroundColor: themeParams.bg_color,
        color: themeParams.text_color,
        padding: '5px 10px',
        borderRadius: '10px',
        marginRight: '5px',
        fontSize: '12px',
    });

    const PayTag = styled("span")({
        backgroundColor: themeParams.bg_color,
        padding: '5px 10px',
        borderRadius: '10px',
        marginRight: '5px',
        marginBottom: '20px',
        fontSize: '12px',
    });

    const PageTitle = styled(Typography)({
        color: themeParams.text_color,
        textAlign: 'center',
        margin: '20px 0',
    });

    useEffect(() => {
        const fetchPayments = async () => {
            setIsFetching(true);
            try {
                const response = await axios.get(`/v2/payments?page=${page}&size=10&course_id=${courseID}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setPayments(prevPayments => [...prevPayments, ...response.data.data]);
                setHasMore(page < response.data.pagination.total_pages);
                setLoading(false);
                setIsFetching(false);
            } catch (error) {
                console.error("Ошибка при загрузке платежей:", error);
                setLoading(false);
                setIsFetching(false);
            }
        };

        fetchPayments();
    }, [page, token, courseID]);

    useEffect(() => {
        const handleScroll = () => {
            if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight || isFetching) return;
            setPage(prevPage => prevPage + 1);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isFetching]);

    return (
        <div>
            <List>
                {payments.map(payment => (
                    <StyledCard key={payment.id}>
                        <CardContent>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar alt={payment.student.first_name} src={payment.course.image} />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={payment.step.title}
                                    secondary={payment.course.title}
                                />
                            </ListItem>
                            <span style={{ margin: 10 }}>
                                {payment.amount > 0 && (
                                    <AmountTypography variant="body2" color="red">
                                        <PayTag>-{payment.amount} {payment.currency}</PayTag>
                                    </AmountTypography>
                                )}
                                {payment.refund_amount > 0 && (
                                    <AmountTypography variant="body2" color="green">
                                        <PayTag>+{payment.refund_amount} {payment.currency}</PayTag>
                                    </AmountTypography>
                                )}
                            </span>
                            <Typography variant="body2" color="textSecondary">
                                {translate(language, 'status')}: {payment.status}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                {translate(language, 'date')}: {new Date(payment.created_at).toLocaleString()}
                            </Typography>
                        </CardContent>
                    </StyledCard>
                ))}
            </List>
            {loading && (
                <>
                    <SkeletonPayment themeParams={themeParams} />
                    <SkeletonPayment themeParams={themeParams} />
                </>
            )}
            {payments.length === 0 && !loading && (
                <Typography variant="body2" color="textSecondary" align="center" marginY={2}>
                    {translate(language, 'no_payments')}
                </Typography>
            )}
        </div>
    );
};

export default Payments;