import React from 'react';
import { Avatar, CardContent, Typography, Card, LinearProgress } from '@mui/material';
import { styled } from '@mui/system';
import Payments from "./Payments";
import {translate} from "./translations";

export default function Profile({ accessToken, user, course, themeParams, language }) { // Add language prop
    if (!user) return null;

    // Function to convert hex to rgb
    const hexToRgb = (hex) => {
        let bigint = parseInt(hex.substring(1), 16);
        let r = (bigint >> 16) & 255;
        let g = (bigint >> 8) & 255;
        let b = bigint & 255;
        return [r, g, b];
    };

    const rgbToCss = (rgb) => `rgb(${rgb.join(', ')})`;

    const lightenColor = (rgb, amount) => {
        return rgb.map(value => Math.min(255, value + amount));
    };

    const primaryColorRgbArray = hexToRgb(themeParams.secondary_bg_color); // --tg-theme-bg-color
    const primaryColorRgb = rgbToCss(primaryColorRgbArray);

    // Apply transformation to get the secondary color
    const secondaryColorRgbArray = lightenColor(primaryColorRgbArray, 50); // Increase brightness
    const secondaryColorRgb = rgbToCss(secondaryColorRgbArray);

    const StyledCard = styled(Card)(({ theme }) => ({
        background: `linear-gradient(${primaryColorRgb} 10.06%, ${secondaryColorRgb} 128.06%)`,
        width:'100%',
        textAlign: 'center',   // Center align text
        padding: '20px',       // Add padding inside the card
    }));

    const StyledTypography = styled(Typography)({
        color: themeParams.text_color
    });

    const StyledCardLessons = styled(Card)({
        backgroundColor: themeParams.section_bg_color,
        borderRadius: '20px',
        marginLeft: '20px',
        marginRight: '20px',
        marginTop: '7px',
        marginBottom: '20px',
    });

    const SectionNameTypography = styled(Typography)({
        color: themeParams.section_header_text_color,
        fontSize: '0.80rem', // Smaller font size
        marginLeft: '40px',
        marginTop: '20px',
        textAlign: 'left', // Align text to the left
        textTransform: 'uppercase', // Uppercase text
        letterSpacing: '0.1em', // Spacing between letters to match the style
    });

    return (
        <div>
            <StyledCard className="max-w-md mx-auto mt-10">
                <Avatar
                    alt={user.full_name}
                    src={user.avatar}
                    sx={{width: 100, height: 100, margin: '0 auto 10px'}}
                />
                <StyledTypography variant="h5">
                    {user.full_name}
                </StyledTypography>
                <StyledTypography variant="body1">
                    {user.ref_id}
                </StyledTypography>
            </StyledCard>

            <SectionNameTypography>
                {translate(language, 'payments')}
            </SectionNameTypography>

            <StyledCardLessons className="max-w-xl mx-auto mt-10">
                <CardContent className="flex flex-col items-center p-6">
                    <Payments token={accessToken} themeParams={themeParams} courseID={course.id} language={language}/>
                </CardContent>
            </StyledCardLessons>
        </div>
    );
}