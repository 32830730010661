import React from "react";
import { Card, CardContent, Stepper, Step, StepLabel, Skeleton } from '@mui/material';
import { styled } from '@mui/system';
import StepIcon, { stepIconClasses } from '@mui/material/StepIcon'

export default function CourseCardSkeleton({ themeParams }) {
    const StyledCard = styled(Card)({
        backgroundColor: themeParams.secondary_bg_color,
        borderRadius: '20px',
        margin: '20px'
    });

    const StepIconStyled = styled(StepIcon)(({ theme }) => ({
        color: themeParams.hint_color,
        display: 'block',
        [`&.${stepIconClasses.completed}, &.${stepIconClasses.active}`]: {
            color: themeParams.button_color,
        },
    }))

    const StyledStep = styled(Step)({});

    const CustomStepIcon = (props) => {
        const { active, completed, icon } = props;

        // Определяем, какой смайлик использовать
        let emoji = '📓';
        return <span>{emoji}</span>;
    };

    return (
        <StyledCard className="max-w-xl mx-auto mt-10">
            <CardContent className="flex flex-col items-center p-6">
                <Skeleton variant="text" width="60%" height={40} className="text-center mb-2" />
                <Skeleton variant="text" width="80%" height={24} className="text-center mb-4" />
                <Skeleton variant="text" width="40%" height={20} className="text-center mb-4" />
                <Skeleton variant="text" width="40%" height={20} className="text-center mb-4" />

                <Stepper orientation="vertical">
                    {[0, 1, 2, 3, 4, 5].map((index) => (
                        <StyledStep active={false} key={index}>
                            <StepLabel StepIconComponent={CustomStepIcon} ><Skeleton variant="text" width="80%" height={24} /></StepLabel>
                        </StyledStep>
                    ))}
                </Stepper>
            </CardContent>
        </StyledCard>
    );
}