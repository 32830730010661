import React, {useState, useEffect, lazy, Suspense, useRef} from "react";
import './App.css';
import axios from 'axios';
import CourseCardSkeleton from './CourseCardSkeleton';
import {styled} from '@mui/system';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {Card, CardContent, Typography, IconButton} from "@mui/material";
import PluginSkeleton from "./PluginSkeleton";
import Text from "./step-view/Text";
import Image from "./step-view/Image";
import Video from "./step-view/Video";
import Invoice from "./step-view/Invoice";
import useWebApp from './twa/useWebApp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const PluginComponent = lazy(() => import('./PluginComponent'));

export default function Content({accessToken, courseId, step, stepID, themeParams, language}) {
    const [componentLoading, setComponentLoading] = useState(true);
    const [components, setComponents] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null); // New state for error message
    const [dark, setDark] = useState(false);

    let WebApp = useWebApp();

    const componentsRef = useRef(null);

    useEffect(() => {
        setComponentLoading(true);

        if (WebApp.platform !== "macos") {
            WebApp.enableClosingConfirmation();
        }

        const colorScheme = WebApp.colorScheme || 'light';
        setDark(colorScheme === 'dark');

        axios.get(`/v2/steps/${stepID}/components`, {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'X-CURRENT-STEP-ID': `${stepID}`,
            }
        }).then(response => {
            setComponents(response.data.data);
            setComponentLoading(false);
        }).catch(error => {
            setErrorMessage("You do not have access to this content.");
            setComponentLoading(false);
        });

    }, [accessToken, stepID]);

    const StyledContainer = styled('div')({
        backgroundColor: themeParams.secondary_bg_color,
        color: themeParams.text_color
    });

    const theme = createTheme({
        palette: {
            mode: dark ? 'dark' : 'light',
        },
    });

    const StyledCard = styled(Card)({
        backgroundColor: themeParams.section_bg_color,
        color: themeParams.text_color,
        borderRadius: '20px',
        margin: '20px'
    });

    let Component;

    switch (step.type) {
        case "text":
            Component = Text;
            break;
        case "image":
            Component = Image;
            break;
        case "video":
            Component = Video;
            break;
        case "invoice":
            Component = Invoice;
            break;

        default:
            return (
                <div className="flex flex-col flex-auto justify-start items-start w-full">
                    Unknown step type: {step.type}
                </div>
            );
    }

    const StyledContainerStep = styled('div')({
        display: 'flex',
        marginTop: '10px',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden', // Hides any overflowing content
        overflowY: 'auto', // Adds a scrollbar for vertical overflow
    });

    const ArrowContainer = styled('div')({
        display: 'flex',
        justifyContent: 'center',
        marginTop: '10px',
    });

    const handleScrollDown = () => {
        componentsRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <>
            <StyledContainerStep>
                <StyledCard className="max-w-xl mx-auto mt-10">
                    <Component step={step}/>
                </StyledCard>
            </StyledContainerStep>
            {components.length > 0 && (
                <>
                    <ArrowContainer>
                        <IconButton onClick={handleScrollDown}>
                            <ExpandMoreIcon fontSize="large"/>
                        </IconButton>
                    </ArrowContainer>
                    <StyledContainer ref={componentsRef}>
                        {componentLoading ? (
                            <StyledCard className="max-w-xl mx-auto mt-10">
                                <CardContent className="flex flex-col items-center p-6">
                                    <CourseCardSkeleton themeParams={themeParams}/>
                                </CardContent>
                            </StyledCard>
                        ) : (
                            <StyledCard className="max-w-xl mx-auto mt-10">
                                <CardContent className="flex flex-col items-center p-6">
                                    <Suspense fallback={
                                        <React.Fragment>
                                            {(components.length > 0 ? components : [{}]).map((_, index) => (
                                                <PluginSkeleton key={index}/>
                                            ))}
                                        </React.Fragment>
                                    }>
                                        {errorMessage ? (
                                            <Typography variant="h6" color="error" align="center"
                                                        style={{marginTop: '20px'}}>
                                                {errorMessage}
                                            </Typography>
                                        ) : null}

                                        {components.map((component) => (
                                            <PluginComponent
                                                key={component.id} // Added key to the Plugin component
                                                accessToken={accessToken}
                                                component={component}
                                                components={components}
                                                isSyllabus={false}
                                                viewerWidthPercent={100}
                                                themeParams={themeParams}
                                                mode={dark ? 'dark' : 'light'}
                                                language={language} // Pass the language to the Plugin component
                                            />
                                        ))}
                                    </Suspense>
                                </CardContent>
                            </StyledCard>
                        )}
                    </StyledContainer>
                </>
            )}
        </>
    );
}