// store.js
import { createStore, combineReducers } from 'redux';

// Action Types
const SET_TAB = 'SET_TAB';
const SET_SELECTED_ASSIGNMENT = 'SET_SELECTED_ASSIGNMENT';

// Action Creators
export const setTab = (tab) => ({
    type: SET_TAB,
    payload: tab,
});

export const setSelectedAssignment = (assignment) => ({
    type: SET_SELECTED_ASSIGNMENT,
    payload: assignment,
});

// Initial State
const initialState = {
    tab: 0,
    selectedAssignment: null,
};

// Reducers
const tabReducer = (state = initialState.tab, action) => {
    switch (action.type) {
        case SET_TAB:
            return action.payload;
        default:
            return state;
    }
};

const assignmentReducer = (state = initialState.selectedAssignment, action) => {
    switch (action.type) {
        case SET_SELECTED_ASSIGNMENT:
            return action.payload;
        default:
            return state;
    }
};

const rootReducer = combineReducers({
    tab: tabReducer,
    selectedAssignment: assignmentReducer,
});

const store = createStore(rootReducer);

export default store;