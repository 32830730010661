import React from 'react';
import { Typography } from "@mui/material";
import DOMPurify from 'dompurify';

function Text({ step }) {
    const createMarkup = (text) => {
        let sanitizedText = text
            .replace(/\n/g, '<br/>') // Замена \n на <br/>
            .replace(/<tg-spoiler>/g, '<span style="background-color: #dadada;">') // Замена tg-spoiler на div с серым фоном
            .replace(/<\/tg-spoiler>/g, '</span>') // Замена закрывающего tg-spoiler на закрывающий div
            .replace(/<pre><code class="language-(.*?)">/g, '<div style="background-color: #f5f5f5; padding: 15px; border-radius: 8px; overflow-x: auto;">') // Стилизация блока кода
            .replace(/<\/code><\/pre>/g, '</div>'); // Закрытие стилизованного div

        sanitizedText = DOMPurify.sanitize(sanitizedText);

        return { __html: sanitizedText };
    }

    return (
        <Typography style={{
            padding: '16px',
        }} component="div" dangerouslySetInnerHTML={createMarkup(step.text)} />
    );
}

export default Text;
