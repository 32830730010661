import React, { useCallback, useEffect, useState } from 'react';
import UserProfile from './UserProfile';
import UserProfileSkeleton from './UserProfileSkeleton';
import CourseCardSkeleton from './CourseCardSkeleton';
import { styled } from "@mui/system";
import { Card, CardContent, Step, StepLabel, Stepper, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PendingIcon from "@mui/icons-material/Pending";
import LockIcon from "@mui/icons-material/Lock";
import StepIcon, { stepIconClasses } from "@mui/material/StepIcon";
import ExpandableText from "./ExpandableText";
import Content from "./Content";
import useWebApp from "./twa/useWebApp";
import {translate} from "./translations";

const Dashboard = ({ loading, courseLoading, user, course, themeParams, language, accessToken, activeTime, botID, chatID }) => {
    const [activeStepId, setActiveStepId] = useState(null);
    const [currentActiveTime, setCurrentActiveTime] = useState(activeTime);
    const WebApp = useWebApp(); // Move the useWebApp hook inside the component

    const handleBackClick = useCallback(() => {
        setActiveStepId(null);
        if (WebApp) WebApp.BackButton.hide();
    }, [WebApp]);

    useEffect(() => {
        if (WebApp) {
            WebApp.setHeaderColor('secondary_bg_color');
        }
    }, [WebApp]);

    useEffect(() => {
        if (activeTime !== currentActiveTime) {
            setCurrentActiveTime(activeTime);
            setActiveStepId(null);
        }
    }, [activeTime]);

    useEffect(() => {
        if (WebApp) {
            WebApp.BackButton.onClick(handleBackClick);
            return () => {
                WebApp.BackButton.hide();
            };
        }
    }, [WebApp, handleBackClick]); // Ensure dependencies are properly set

    if (!course) return null;

    const CustomCheckCircleIcon = styled(CheckCircleIcon)(({ theme }) => ({
        color: '#4CAF50' // themeParams.button_color,
    }));

    const CustomPendingIcon = styled(PendingIcon)(({ theme }) => ({
        color: themeParams.button_color,
    }));

    const CustomLockIcon = styled(LockIcon)(({ theme }) => ({
        color: 'gray' // themeParams.button_color,
    }));

    const StyledCardLessons = styled(Card)({
        backgroundColor: themeParams.section_bg_color,
        borderRadius: '20px',
        marginLeft: '20px',
        marginRight: '20px',
        marginTop: '7px',
        marginBottom: '20px',
    });

    const StyledStep = styled(Step)({});

    const SectionNameTypography = styled(Typography)({
        color: themeParams.section_header_text_color,
        fontSize: '0.80rem', // Smaller font size
        marginLeft: '40px',
        textAlign: 'left', // Align text to the left
        textTransform: 'uppercase', // Uppercase text
        letterSpacing: '0.1em', // Spacing between letters to match the style
    });

    const CustomStepIcon = (props) => {
        const { active, completed, icon } = props;

        // Determine which emoji to use
        let emoji;
        if (active) {
            emoji = <CustomPendingIcon />; // Emoji for the current active step
        } else {
            if (completed) {
                emoji = <CustomCheckCircleIcon />; // Emoji for the completed step
            } else {
                emoji = <CustomLockIcon />; // Emoji for the future step
            }
        }

        // Return the emoji instead of the default step number
        return <span>{emoji}</span>;
    };

    const handleStepClick = (step) => {
        if (step.is_completed && !step.is_current) {
            setActiveStepId(step);
            if (WebApp) WebApp.BackButton.show();
            window.scrollTo(0, 0);
        } else {
            if (step.is_current) {
                WebApp.showAlert(translate(language, "current_step_locked"));
            } else {
                WebApp.showAlert(translate(language, "step_locked"));
            }
        }
    };

    return (
        <>
            {!activeStepId && (
                <>
                    {loading ? <UserProfileSkeleton themeParams={themeParams} /> : <UserProfile botID={botID} chatID={chatID} accessToken={accessToken} user={user} course={course} themeParams={themeParams} language={language} />}
                    {courseLoading ? <CourseCardSkeleton themeParams={themeParams} /> :
                        <>
                            <SectionNameTypography>
                                {translate(language, "lessons")}
                            </SectionNameTypography>
                            <StyledCardLessons className="max-w-xl mx-auto mt-10">
                                <CardContent className="flex flex-col items-center p-6">
                                    {course.steps && course.steps.length > 0 && (
                                        <Stepper orientation="vertical">
                                            {course.steps.map((step, index) => (
                                                <StyledStep
                                                    completed={step.is_completed}
                                                    active={step.is_current}
                                                    key={step.id}
                                                    onClick={() => handleStepClick(step)}
                                                >
                                                    <StepLabel StepIconComponent={CustomStepIcon}>
                                                        {step.name} {/* No translation needed for dynamic data */}
                                                    </StepLabel>
                                                </StyledStep>
                                            ))}
                                        </Stepper>
                                    )}
                                </CardContent>
                            </StyledCardLessons>
                        </>
                    }
                </>
            )}
            {activeStepId && (
                <Content
                    accessToken={accessToken}
                    courseId={course.id}
                    stepID={activeStepId.id}
                    step={activeStepId}
                    themeParams={themeParams}
                    language={language}
                />
            )}
        </>
    );
};

export default Dashboard;