import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Avatar, Typography, Card, LinearProgress, Button } from '@mui/material';
import { styled } from '@mui/system';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { translate } from './translations'; // Import the translate function
import useWebApp from "./twa/useWebApp";
import { useLocation } from 'react-router-dom';

export default function UserProfile({ accessToken, user, course, themeParams, language, botID, chatID }) { // Add language prop
    const location = useLocation();
    const [showResetButton, setShowResetButton] = useState(false);

    const WebApp = useWebApp(); // Move the useWebApp hook inside the component

    if (!user) return null;

    // Function to convert hex to rgb
    const hexToRgb = (hex) => {
        let bigint = parseInt(hex.substring(1), 16);
        let r = (bigint >> 16) & 255;
        let g = (bigint >> 8) & 255;
        let b = bigint & 255;
        return [r, g, b];
    };

    const rgbToCss = (rgb) => `rgb(${rgb.join(', ')})`;

    const lightenColor = (rgb, amount) => {
        return rgb.map(value => Math.min(255, value + amount));
    };

    const primaryColorRgbArray = hexToRgb(themeParams.secondary_bg_color); // --tg-theme-bg-color
    const primaryColorRgb = rgbToCss(primaryColorRgbArray);

    // Apply transformation to get the secondary color
    const secondaryColorRgbArray = lightenColor(primaryColorRgbArray, 50); // Increase brightness
    const secondaryColorRgb = rgbToCss(secondaryColorRgbArray);

    const StyledCard = styled(Card)(({ theme }) => ({
        background: `linear-gradient(${primaryColorRgb} 10.06%, ${secondaryColorRgb} 128.06%)`,
        borderRadius: '20px',  // Rounded corners
        margin: '20px',        // Margin from screen edges
        textAlign: 'center',   // Center align text
        padding: '20px',       // Add padding inside the card
    }));

    const StyledTypography = styled(Typography)({
        color: themeParams.text_color
    });

    const StyledHintTypography = styled(Typography)({
        color: themeParams.hint_color
    });

    const StyledLinearProgressContainer = styled('div')({
        position: 'relative',
        width: '100%',
        margin: '10px 0',
    });

    const StyledLinearProgress = styled(LinearProgress)(({ value }) => ({
        height: '20px',  // Increase ProgressBar height
        borderRadius: '10px',  // More rounded corners for ProgressBar
        backgroundColor: themeParams.secondary_bg_color,
        cursor: 'pointer', // Add cursor pointer to indicate it's clickable
        '& .MuiLinearProgress-bar': {
            borderRadius: '10px',  // Ensure inner bar also has rounded corners
            backgroundColor: value === 100 ? '#4CAF50' : themeParams.button_color, // Change color when completed
        }
    }));

    const ProgressText = styled(Typography)({
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        color: themeParams.button_text_color,
        fontWeight: 'bold',
    });

    const StyledButton = styled(Button)(({ theme }) => ({
        marginTop: '10px',
        color: themeParams.button_text_color,
        backgroundColor: themeParams.button_color,
        '&:hover': {
            backgroundColor: themeParams.button_color,
            opacity: 0.8,
        },
        borderRadius: '12px', // Rounded corners for the button
        padding: '10px 20px', // Add padding inside the button
    }));

    const CenteredImage = styled('img')({
        width: '100%',
        borderRadius: '12px',
        display: 'block',
        margin: '0 auto',
    });

    const resetProgress = async () => {
        if (WebApp) {
            WebApp.showConfirm(translate(language, 'reset_confirm_message'), async (agree) => {
                if (agree) {
                    const requestData = {
                        type: 'reset_progress',
                        user_id: user ? user.id : "",
                        bot_id: botID,
                        chat_id: chatID,
                    };

                    const config = {
                        headers: {
                            'Authorization': `Bearer ${accessToken}`
                        }
                    };

                    axios.post("/v1/bots/webapp", requestData, config)
                        .then(response => {
                            console.log("Successfully sent:", response.data);
                            WebApp.close();
                        })
                        .catch(error => {
                            WebApp.showAlert("error: " + error.response.data.data.message);
                            console.error("Error sending data:", error);
                        });
                }
            });
        }
    };

    const completedPercent = course && course.completed_percent ? parseInt(course.completed_percent, 10) : null;

    const getCourseTitle = () => {
        let title = course.title;
        if (title.length > 55) { // Assuming 40 as the character limit
            title = title.substring(0, 52) + '...';
        }
        if (completedPercent === 100) {
            title += " 🎉";
        }
        return title;
    };

    const SectionNameTypography = styled(Typography)({
        color: themeParams.section_header_text_color,
        fontSize: '0.80rem', // Smaller font size
        marginLeft: '40px',
        marginTop: '20px',
        textAlign: 'left', // Align text to the left
        textTransform: 'uppercase', // Uppercase text
        letterSpacing: '0.1em', // Spacing between letters to match the style
    });

    const handleProgressBarClick = () => {
        setShowResetButton(!showResetButton);
    };

    const DescriptionTypography = styled(Typography)({
        color: themeParams.text_color,
        marginTop: '20px',
        textAlign: 'center',
    });

    return (
        <div>
            <SectionNameTypography>
                {course.title}
            </SectionNameTypography>

            <StyledCard className="max-w-md mx-auto mt-10">
                {course.image_url &&
                    <CenteredImage src={course.image_url} alt={course.title} />}

                {completedPercent > 0 && (
                    <div style={{ marginTop: 10, width: '100%' }}>
                        <StyledHintTypography color="textSecondary" className="text-center mb-2">
                            {user.full_name}
                        </StyledHintTypography>
                        <StyledLinearProgressContainer onClick={handleProgressBarClick}>
                            <StyledLinearProgress variant="determinate" value={completedPercent} />
                            <ProgressText variant="body2">{`${completedPercent}%`}</ProgressText>
                        </StyledLinearProgressContainer>
                        {showResetButton && (
                            <StyledButton onClick={resetProgress} variant="contained" startIcon={<RestartAltIcon />}>
                                {translate(language, 'reset_progress')}
                            </StyledButton>
                        )}
                    </div>
                )}
            </StyledCard>
        </div>
    );
}
