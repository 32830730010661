import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import {
    Card,
    CardContent,
    Typography,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    CircularProgress,
    Select,
    MenuItem,
    FormControl,
    InputLabel
} from '@mui/material';
import { styled } from '@mui/system';
import useWebApp from "./twa/useWebApp";
import IframeComm from "react-iframe-comm";
import { debounce } from "lodash";
import { BXStyles } from './PluginStyle';
import moment from 'moment';

import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import PendingIcon from '@mui/icons-material/Pending';
import { translate } from './translations'; // Import the translate function
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedAssignment } from './store';

const AmountTypography = styled(Typography)(({ theme, color }) => ({
    color: color,
}));

const Assignments = ({ token, themeParams, courseID, language, mode = 'light' }) => {
    const [assignments, setAssignments] = useState([]);
    const [selectedComponent, setSelectedComponent] = useState(null);
    const [iframeHeight, setIframeHeight] = useState(500);
    const [loading, setLoading] = useState(true);
    const [iframeLoaded, setIframeLoaded] = useState(false);

    const WebApp = useWebApp();
    const dispatch = useDispatch();
    const selectedAssignment = useSelector(state => state.selectedAssignment);

    const handleBackClick = useCallback(() => {
        dispatch(setSelectedAssignment(null));
        setSelectedComponent(null);
        if (WebApp) WebApp.BackButton.hide();
    }, [WebApp, dispatch]);

    useEffect(() => {
        if (WebApp && token) {
            WebApp.BackButton.onClick(handleBackClick);
        }

        return () => {
            if (WebApp) WebApp.BackButton.hide();
        };
    }, [WebApp, token, handleBackClick]);

    const StyledCard = styled(Card)(({ status }) => ({
        backgroundColor: themeParams.section_bg_color,
        border: status === 'approved' ? `2px solid ${themeParams.success_border_color}` : 'none',
        borderRadius: '20px',
        margin: '20px',
        position: 'relative',
        cursor: 'pointer',
    }));

    const date = (dt) => {
        return moment(dt).format('DD.MM.YY hh:mm');
    };

    const PageTitle = styled(Typography)({
        color: themeParams.text_color,
        textAlign: 'center',
        margin: '20px 0',
    });

    const ListLabel = styled('span')({
        color: themeParams.hint_color,
        fontSize: '13px',
    });

    const IconContainer = styled('div')(({ status }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '40px',
        height: '40px',
        borderRadius: '20px',
        backgroundColor: status === 'pending' ? '#87CEFA' :
            status === 'approved' ? '#90EE90' :
                status === 'rejected' ? '#FF6347' :
                    themeParams.bg_color,
        '@media (max-width: 600px)': {
            position: 'static',
            marginTop: '10px',
            alignSelf: 'flex-start',
        },
    }));

    const InfoContainer = styled('div')({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginTop: '10px',
        gap: '10px',
        '@media (min-width: 600px)': {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
    });

    const fetchAssignments = async () => {
        try {
            const response = await axios.get(`/v2/assignments?course_id=${courseID}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setAssignments(response.data.data);
            setLoading(false);
        } catch (error) {
            console.error("Ошибка при загрузке заданий:", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchAssignments();
    }, [token, courseID]);

    useEffect(() => {
        if (selectedAssignment && !selectedComponent) {
            setSelectedComponent(selectedAssignment.components[0]);
            if (WebApp) WebApp.BackButton.show();
        }
    }, [selectedAssignment, selectedComponent]);

    const handleCardClick = useCallback((assignment) => {
        dispatch(setSelectedAssignment(assignment));
        setSelectedComponent(assignment.components[0]);
        setIframeLoaded(false);
        if (WebApp) WebApp.BackButton.show();
    }, [WebApp, dispatch]);

    const setDebouncedHeight = useCallback(debounce((height) => {
        setIframeHeight(height);
    }, 5), []);

    // Function to handle message received from Iframe
    const onReceiveMessage = (event) => {
        let id = '';
        let namespace = '';
        if (event.data) {
            try {
                const data = JSON.parse(event.data);
                id = data.id;
                namespace = data.namespace;
            } catch (e) {
                return;
            }
        }

        if (namespace !== 'view') {
            return;
        }
        //data.id === selectedAssignment.component_id && data.responseId === response_id

        if (id === selectedAssignment.component_id) {
            const type = JSON.parse(event.data).type;
            switch (type) {
                case 'set_height':
                    setHeight(event.data);
                    break;
                case 'content_loaded':
                    contentLoaded(event.data);
                    break;
                case 'fix_view':
                    fixView(event.data);
                    break;
                case 'answer_submit':
                    answerSubmit(event.data);
                    break;
                case 'show_message':
                    showMessageBox(event.data);
                    break;
                case 'uploadFile':
                    uploadFile(event);
                    break;
            }
        }
    };

    const setHeight = (resp) => {
        const height = JSON.parse(resp).height;
        if (height > 0) {
            setDebouncedHeight(height);
        }
    };

    const contentLoaded = (resp) => {
        const r = JSON.parse(resp);
        if (r.id === selectedAssignment.component_id) {
            setLoading(false);
            setIframeLoaded(true);
        }
    }

    const fixView = (event) => {
    };

    const answerSubmit = (event) => {
        const r = JSON.parse(event);
        if (r.id === selectedAssignment.component_id) {
            const answerData = {
                component_id: selectedAssignment.component_id,
                input_data: JSON.stringify(r.state),
            };

            const config = {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            axios.post('v1/components/response', answerData, config).then(response => {
                let newComponent = response.data.data;
                selectedComponent.url = newComponent.payload.view;
                setSelectedComponent(selectedComponent);

                axios.get(`/v2/assignments?course_id=${courseID}`, config).then(response => {
                    setAssignments(response.data.data);
                    let s = response.data.data.find(a => a.component_id === selectedAssignment.component_id);
                    dispatch(setSelectedAssignment(s));
                    setSelectedComponent(s.components[0]);
                });

            }).catch(error => {
                console.error('Upload error:', error);
            });
        }
    };

    const showMessageBox = (event) => {
        const r = JSON.parse(event);
        if (r.id === selectedAssignment.component_id) {
            const message = r.message;
            WebApp.showAlert(message);
        }
    }

    const SectionNameTypography = styled(Typography)({
        color: themeParams.section_header_text_color,
        fontSize: '0.80rem', // Smaller font size
        marginLeft: '40px',
        marginTop: '20px',
        textAlign: 'left', // Align text to the left
        textTransform: 'uppercase', // Uppercase text
        letterSpacing: '0.1em', // Spacing between letters to match the style
    });

    // Function to convert hex to rgb
    const hexToRgb = (hex) => {
        let bigint = parseInt(hex.substring(1), 16);
        let r = (bigint >> 16) & 255;
        let g = (bigint >> 8) & 255;
        let b = bigint & 255;
        return [r, g, b];
    };

    const rgbToCss = (rgb) => `rgb(${rgb.join(', ')})`;

    const lightenColor = (rgb, amount) => {
        return rgb.map(value => Math.min(255, value + amount));
    };

    const primaryColorRgbArray = hexToRgb(themeParams.bg_color); // --tg-theme-bg-color
    const primaryColorRgb = rgbToCss(primaryColorRgbArray);

    // Apply transformation to get the secondary color
    const secondaryColorRgbArray = lightenColor(primaryColorRgbArray, 50); // Increase brightness
    const secondaryColorRgb = rgbToCss(secondaryColorRgbArray);

    const StyledCardHeader = styled(Card)(({ theme }) => ({
        background: `linear-gradient(${secondaryColorRgb} 0.06%, ${primaryColorRgb} 128.06%)`,
        width:'100%',
        textAlign: 'center',   // Center align text
        padding: '20px',       // Add padding inside the card
    }));

    const uploadFile = (event) => {
        const r = JSON.parse(event.data);
        if (r.id === selectedAssignment.component_id) {
            const fileDataUrl = r.data;
            const originalFileName = r.originalFileName;

            fetch(fileDataUrl)
                .then(res => res.blob())
                .then(blob => {
                    const formData = new FormData();
                    formData.append('file', blob);
                    formData.append('original_file_name', originalFileName);

                    // Предполагаем, что axios уже настроен с baseURL и заголовками авторизации
                    axios.post('v1/storage/upload/file', formData, {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'multipart/form-data'
                        },
                        onUploadProgress: function (progressEvent) {
                            const progress = (progressEvent.loaded / progressEvent.total) * 100;
                            event.source.postMessage({
                                type: 'uploadFileProgress',
                                id: selectedComponent.id,
                                progress: progress
                            }, "*");
                        }
                    })
                        .then(response => {
                            const file = response.data.data;
                            let fileURL = "https://t.coob.app/coob/" + file.path;

                            if (event.source) {
                                event.source.postMessage({
                                    type: 'uploadFileComplete',
                                    id: selectedComponent.id,
                                    fileURL: fileURL,
                                    fileSize: file.meta.size,
                                    fileID: file.id
                                }, "*");
                            }
                        })
                        .catch(error => {
                            console.error('Upload error:', error);
                            if (event.source) {
                                event.source.postMessage({
                                    type: 'uploadFileError',
                                    id: selectedComponent.id,
                                    message: 'Upload failed: ' + error.message
                                }, "*");
                            }
                        });
                })
                .catch(error => {
                    console.error('Error converting Data URL to Blob:', error);
                    if (event.source) {
                        event.source.postMessage({
                            type: 'uploadFileError',
                            id: selectedComponent.id,
                            message: 'Error converting Data URL to Blob: ' + error.message
                        }, "*");
                    }
                });
        }
    };

    return (
        <div>
            {!selectedAssignment ? (
                <>

                    <SectionNameTypography>
                        {translate(language, 'assignments')}
                    </SectionNameTypography>
                    <List>
                        {assignments?.map(assignment => (
                            <StyledCard key={assignment.id} status={assignment.status}
                                        onClick={() => handleCardClick(assignment)}>
                                <CardContent>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <IconContainer status={assignment.status}>
                                                {assignment.status === 'approved' &&
                                                    <ThumbUpIcon style={{ color: 'green' }} />}
                                                {assignment.status === 'rejected' &&
                                                    <ThumbDownIcon style={{ color: '#8B0000' }} />}
                                                {assignment.status === 'pending' &&
                                                    <PendingIcon style={{ color: '#2E5984' }} />}
                                            </IconContainer>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={<Typography variant="body1" noWrap>{assignment.course.title}</Typography>}
                                            secondary={<Typography variant="body2" color="textSecondary">{assignment.course?.step.title}</Typography>}
                                        />
                                    </ListItem>
                                    <InfoContainer>
                                        <ListLabel>{translate(language, 'attempts')}: {assignment.components.length}</ListLabel>
                                        <ListLabel>{translate(language, 'comments')}: (0)</ListLabel>
                                        <ListLabel>{translate(language, 'created')}: {new Date(assignment.components[0].created_at).toLocaleString()}</ListLabel>
                                    </InfoContainer>
                                </CardContent>
                            </StyledCard>
                        ))}
                    </List>
                </>
            ) : (
                <>
                    <div style={{
                        margin: '20px',
                        padding: '20px',
                    }}>
                        <Typography variant="h5" style={{ marginBottom: '20px' }}>
                            <ListItem>
                                <ListItemAvatar>
                                    <IconContainer status={selectedAssignment.status}>
                                        {selectedAssignment.status === 'approved' &&
                                            <ThumbUpIcon style={{ color: 'green' }} />}
                                        {selectedAssignment.status === 'rejected' &&
                                            <ThumbDownIcon style={{ color: '#8B0000' }} />}
                                        {selectedAssignment.status === 'pending' &&
                                            <PendingIcon style={{ color: '#2E5984' }} />}
                                    </IconContainer>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={<Typography variant="body1" noWrap>{selectedAssignment.course.title}</Typography>}
                                    secondary={<Typography variant="body2" color="textSecondary">{selectedAssignment.course?.step?.title}</Typography>}
                                />
                            </ListItem>
                        </Typography>
                        <FormControl fullWidth style={{ marginBottom: '20px' }}>
                            <InputLabel>{translate(language, 'attempts')}</InputLabel>
                            <Select
                                value={selectedComponent?.id}
                                onChange={(e) => setSelectedComponent(selectedAssignment.components.find(c => c.id === e.target.value))}
                            >
                                {selectedAssignment.components.map((component, index) => (
                                    <MenuItem key={component.id} value={component.id}>
                                        {` 🗒️ №${selectedAssignment.components.length - index} ${date(component.created_at)}`}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>

                    <div style={{
                        backgroundColor: themeParams.section_bg_color,
                        color: themeParams.text_color,
                        borderRadius: '20px',
                        margin: '20px',
                        padding: '20px',
                    }}>
                        <IframeComm
                            key={"view_frame_" + selectedAssignment.id}
                            postMessageData={{
                                bookletixMessage: {
                                    css: BXStyles(
                                        100,
                                        '#fff',
                                        '#3A205B',
                                        themeParams.text_color,
                                        themeParams.bg_color,
                                        themeParams.hint_color,
                                        themeParams.link_color,
                                        themeParams.button_color,
                                        themeParams.button_text_color,
                                        themeParams.secondary_bg_color,
                                    ),
                                    data: JSON.stringify({
                                        isSyllabus: false,
                                        mode: mode,
                                        language: language,
                                    }),
                                }
                            }}
                            attributes={{
                                src: selectedComponent?.url,
                                frameBorder: 0,
                                height: iframeHeight,
                                width: '100%',
                                allowFullScreen: true,
                                scrolling: 'no',
                                allow: 'camera; microphone',
                                style: {
                                    opacity: iframeLoaded ? 1 : 0,
                                    transition: 'opacity 0.3s ease-in-out',
                                },
                            }}
                            handleReceiveMessage={onReceiveMessage}
                        />
                    </div>
                </>
            )}
            {loading && (
                <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
                    <CircularProgress />
                </div>
            )}
            {!loading && (assignments == null || assignments?.length === 0) && (
                <Typography variant="body2" color="textSecondary" align="center" marginY={2}>
                    {translate(language, 'no_assignments')}
                </Typography>
            )}
        </div>
    );
};

export default Assignments;