import React, {useState, useEffect, createContext} from "react";
import {useLocation} from 'react-router-dom';
import {styled} from '@mui/system';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Leaderboard';
import AssessmentIcon from '@mui/icons-material/Assessment';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import axios from 'axios';
import useWebApp from './twa/useWebApp';
import Dashboard from './Dashboard';
import Assignments from './Assignments';
import Profile from './Profile';
import Welcome from './Welcome';
import './App.css';
import Notifications from "./Notifications";
import {useSelector, useDispatch} from 'react-redux';
import {setSelectedAssignment, setTab} from "./store";
import {translate} from "./translations";

axios.defaults.baseURL = "https://api.coob.app";
axios.defaults.withCredentials = true;

export const AppContext = createContext();

export default function MyApp() {
    const location = useLocation();
    const correctedSearch = location.search.replace(/&amp;/g, '&');
    const queryParams = new URLSearchParams(correctedSearch);
    const courseId = queryParams.get('course_id');
    const tab = queryParams.get('tab') || "main";
    let botID = queryParams.get('bot_id');
    let chatID = queryParams.get('chat_id');

    const [loading, setLoading] = useState(true);
    const [courseLoading, setCourseLoading] = useState(true);
    const [course, setCourse] = useState(null);
    const [activeTime, setActiveTime] = useState(null);

    const dispatch = useDispatch();
    const value = useSelector(state => state.tab);

    let WebApp = useWebApp();

    const [dark, setDark] = useState(false);
    const [accessToken, setAccessToken] = useState(null);
    const [user, setUser] = useState(null);
    const themeParams = WebApp.themeParams || {};

    const [language, setLanguage] = useState('en'); // Add language state

    useEffect(() => {
        if (WebApp) {
            WebApp.ready();
            setLoading(true);
            setCourseLoading(true);

            let initialTab = 0;
            switch (tab) {
                case 'assignments':
                    initialTab = 1;
                    break;
                case 'notifications':
                    initialTab = 2;
                    break;
                case 'profile':
                    initialTab = 3;
                    break;
                case 'main':
                    initialTab = 0;
                    break;
                default:
                    initialTab = 0;
                    break;
            }
            dispatch(setTab(initialTab));

            const colorScheme = WebApp.colorScheme || 'light';
            setDark(colorScheme === 'dark');

            axios.post("/v1/auth/login/telegram", {
                course_id: courseId,
                web_app_data: WebApp.initData,
            }, {
                withCredentials: true,
            }).then(response => {
                setAccessToken(response.data.data.access_token);
                setUser(response.data.data.user);
                setLanguage(response.data.data.user.language || 'en'); // Set user language
                setLoading(false);

                if (botID === "") {
                    if (response.data.data.meta) {
                        botID = response.data.data.meta.bot_id
                    }
                }
                if (chatID === "") {
                    if (response.data.data.meta) {
                        chatID = response.data.data.meta.chat_id
                    }
                }

                return axios.get(`/v1/courses/${courseId}?is_public=true`, {
                    headers: {
                        'Authorization': `Bearer ${response.data.data.access_token}`
                    }
                });
            }).then(response => {
                setCourse(response.data.data);
                setCourseLoading(false);

                if (!WebApp.isExpanded) {
                    WebApp.expand();
                }

            }).catch(error => {
                WebApp = null;
                console.error("Ошибка:", error);
                setLoading(false);
                setCourseLoading(false);
            });
        }
    }, [WebApp, courseId, tab, dispatch]);

    function sendWebAppData(accessToken) {
        WebApp.MainButton.showProgress();

        const requestData = {
            type: 'start',
            user_id: user ? user.id : "",
            bot_id: botID,
            chat_id: chatID,
        };

        const config = {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        };

        axios.post("/v1/bots/webapp", requestData, config)
            .then(response => {
                console.log("Successfully sent:", response.data);

                WebApp.MainButton.hideProgress();
                WebApp.close();
            })
            .catch(error => {
                WebApp.MainButton.hideProgress();
                WebApp.showAlert("error: " + error.response.data.data.message);
                console.error("Error sending data:", error);
            });
    }

    const StyledContainer = styled('div')({
        backgroundColor: themeParams.secondary_bg_color,
        color: themeParams.text_color,
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingBottom: '70px', // Add bottom padding to prevent content overlay
    });

    const StyledBottomNavigation = styled(BottomNavigation)(({theme}) => ({
        backgroundColor: rgbToCss(hexToRgb(themeParams.header_bg_color)), // Add transparency
        color: themeParams.text_color,
        position: 'fixed',
        bottom: 0,
        height: '70px',
        paddingBottom: '16px',
        width: '100%',
        boxShadow: theme.shadows[4],
        backdropFilter: 'blur(30px)', // Add blur
        zIndex: 1000, // Set high z-index for navigation
        '& .Mui-selected': {
            color: `${themeParams.button_color} !important`,
        },
        '& .MuiBottomNavigationAction-label': {
            color: themeParams.bg_color,
        },
    }));

    // Function to convert hex to rgb
    const hexToRgb = (hex) => {
        let bigint = parseInt(hex.substring(1), 16);
        let r = (bigint >> 16) & 255;
        let g = (bigint >> 8) & 255;
        let b = bigint & 255;
        return [r, g, b];
    };

    const rgbToCss = (rgb) => `rgb(${rgb.join(', ')}, 0.3)`;

    if (!WebApp) {
        return null;
    }

    const theme = createTheme({
        palette: {
            mode: dark ? 'dark' : 'light',
        },
    });

    const renderPage = () => {
        switch (value) {
            case 0:
                return <Dashboard
                    activeTime={activeTime}
                    webApp={WebApp}
                    loading={loading}
                    courseLoading={courseLoading}
                    user={user}
                    accessToken={accessToken}
                    course={course}
                    themeParams={themeParams}
                    language={language}
                    botID={botID}
                    chatID={chatID}
                />;
            case 1:
                return course ? <Assignments token={accessToken} themeParams={themeParams} courseID={course.id}
                                             mode={dark ? 'dark' : 'light'} language={language}/> : null;

            case 2:
                return course ? <Notifications token={accessToken} course={course} user={user} themeParams={themeParams}
                                               language={language}/> : null;

            case 3:
                return course ? <Profile accessToken={accessToken} course={course} user={user} themeParams={themeParams}
                                         language={language}/> : null;
            default:
                return null;
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <StyledContainer>
                <div style={{flex: 1}}>
                    {renderPage()}
                </div>

                    <StyledBottomNavigation
                        showLabels
                        value={value}
                        onChange={(event, newValue) => {
                            setActiveTime(new Date());
                            dispatch(setTab(newValue));
                            dispatch(setSelectedAssignment(null));
                            window.scrollTo(0, 0);
                        }}
                    >
                        <BottomNavigationAction icon={<HomeIcon sx={{fontSize: 28}}/>}/>
                        <BottomNavigationAction icon={<AssessmentIcon sx={{fontSize: 28}}/>}/>
                        <BottomNavigationAction icon={<NotificationsIcon sx={{fontSize: 28}}/>}/>
                        {/* <BottomNavigationAction icon={<PaymentsIcon sx={{ fontSize: 28 }} />} /> */}
                        {user ? (
                            <BottomNavigationAction
                                icon={
                                    <Avatar
                                        alt={user.full_name}
                                        src={user.avatar}
                                        sx={{width: 28, height: 28, margin: '0 auto 0px'}}
                                    />
                                }
                            />
                        ) : (
                            <BottomNavigationAction icon={<PersonIcon sx={{fontSize: 28}}/>}/>
                        )}
                    </StyledBottomNavigation>

            </StyledContainer>
        </ThemeProvider>
    );
}