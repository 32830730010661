import { Skeleton } from '@mui/material';
import React from "react";

export default function PluginSkeleton({ themeParams }) {
    return (
        <div><Skeleton variant="text" width="60%" height={40} className="text-center mb-2"/>
            <Skeleton variant="text" width="80%" height={24} className="text-center mb-4"/>
            <Skeleton variant="text" width="40%" height={20} className="text-center mb-4"/>
            <Skeleton variant="text" width="40%" height={20} className="text-center mb-4"/></div>
    );
}
