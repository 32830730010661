import React from "react";
import { Typography } from "@mui/material";
import DOMPurify from 'dompurify';

function Invoice({ step }) {
    const image_url = step.payload?.image_url;
    const name = step.payload?.name;

    const createMarkup = (text) => {
        let sanitizedText = text
            .replace(/\n/g, '<br/>') // Замена \n на <br/>
            .replace(/<tg-spoiler>/g, '<span style="background-color: #dadada;">') // Замена tg-spoiler на div с серым фоном
            .replace(/<\/tg-spoiler>/g, '</span>') // Замена закрывающего tg-spoiler на закрывающий div
            .replace(/<pre><code class="language-(.*?)">/g, '<div style="background-color: #f5f5f5; padding: 15px; border-radius: 8px; overflow-x: auto;">') // Стилизация блока кода
            .replace(/<\/code><\/pre>/g, '</div>'); // Закрытие стилизованного div

        sanitizedText = DOMPurify.sanitize(sanitizedText);

        return { __html: sanitizedText };
    }

    const styles = {
        markdownRender: {
            paddingLeft: '16px',
            paddingBottom: '16px',
            '& pre': {
                backgroundColor: '#f5f5f5', // Light grey background for preformatted text
                padding: '16px', // Add some padding
                borderRadius: '4px', // Rounded corners
                overflowX: 'auto', // Handle long lines
                fontFamily: '"Courier New", Courier, monospace', // Monospace font for preformatted text
            },
            '& code': {
                backgroundColor: '#eeeeee', // Slightly darker background for code
                padding: '2px 4px', // Padding inside code elements
                borderRadius: '4px', // Slightly rounded corners
                fontFamily: '"Courier New", Courier, monospace', // Monospace font
            },
            '& blockquote': {
                borderLeft: '4px solid #3f51b5', // Left border with primary color
                backgroundColor: '#f9f9f9', // Very light background
                margin: '8px 0', // Margin top and bottom
                padding: '0 16px', // Padding left and right
                fontStyle: 'italic', // Italic text for blockquotes
            },
        },
    };

    return (
        <>
            {/* Adding the course image at the top */}
            {image_url && <img src={image_url} alt={name} style={{ width: '100%', objectFit: 'cover', borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }} />}

            <div style={{ paddingTop: '24px' }}>
                {/* Render the text as Markdown */}
                <Typography component="div">
                    <Typography component="div" style={styles.markdownRender} dangerouslySetInnerHTML={createMarkup(step.text)} />
                </Typography>
            </div>
        </>
    );
}

export default Invoice;