// TelegramWebAppContext.js
import { createContext } from 'react';

export const DEFAULT_WEBAPP =
    typeof window !== 'undefined' && window?.Telegram?.WebApp
        ? window.Telegram.WebApp
        : null;

const TelegramWebAppContext = createContext(DEFAULT_WEBAPP);

export default TelegramWebAppContext;